$domusign-green: #21ca83;
$domusign-color: #1FCA83;
$domusign-white: #fff;
$text-color: #484848;

$loading-color: $domusign-green;
$back-btn-color: #1FCA8399;
$back-btn-text-color: #363636;
$all-documents-nav-item-selected-color: #3ad092;
$stepper-item-color: #52d49d;
$check-mark-color: #4CAF50;
$signature-unselected-border-color: #D3D3D3;
$see-all-color: #33e57f;
$user-has-signed-doc-color: #28a745;
$green-circle-color: #1FCA83AC;
$badge-success-color: #d4edda;
$orange-waiting-color: #FFA500;

// Background
$bg-grey-color: #f1f3f0;
$domusign-bg-grey: #f4f4f4;
$progress-bar-bg-color: #F0F3F0;

$domusign-hover-bg-color: #e9ecef;

// Input
$disable-input-color: #D1D1D1;

// Buttons
$btn-domusign-color: #36EB7E;
$btn-domusign-color2: #169A8E;

$btn-domusign-disabled-color: #E6DADA;
$btn-domusign-disabled-color2: #bdc3c7;

$btn-domusign-download-color: #28C88533;

$btn-domusign-register-color: #e8ebe8;

$account-btn-color: #e8ebe8;
$account-selected-btn-bg-color: #1FCA8322;
