/* You can add global styles to this file, and also import other style files */

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'styles/toastr-bs5-alert';
@import "styles/color";

@import url('https://fonts.googleapis.com/css?family=Muli:200,300,400,400i,600,700,800');

* {
  font-family: 'Muli', sans-serif;
}

body {
  background-color: #F0F3F0 !important;
}

::selection {
  background: $domusign-color;
  color: #fff;
}

.modal-content {
  border: 0 !important;
  border-radius: 25px !important;
}

.champ {
  background-color: white;
  border: 0;
  border-radius: 15px;
  height: 60px;
}

.cursor-pointer {
  cursor: pointer;
}